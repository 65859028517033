import React from "react";
import { Link } from "gatsby";

class CustomLink extends React.Component {
  render() {
    const { children, linkType, location } = this.props;
    if (linkType === "external") {
      return (
        <a className="custom-link" href={location}>
          {children}
        </a>
      );
    } else {
      return (
        <Link className="custom-link" to={location}>
          {children}
        </Link>
      );
    }
  }
}

export default CustomLink;
