import React from 'react'
import './styles.less'
import { Container } from '../Helpers/Container'
import { Col, Row } from 'antd'

class HeroSmall extends React.Component {
  render () {
    let style
    if (this.props.backgroundImage) {
      style = { backgroundImage: 'url(' + this.props.backgroundImage + ')' }
    } else {
      style = {}
    }

    return (
      <div
        className='hero-small'
        style={style}
      >
        <div className='mask'>
          <Container>
            <Row>
              <Col>
                <h1>{ this.props.title }</h1>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

export default HeroSmall
