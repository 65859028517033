module.exports = {
  siteTitle: "Starter", // Site title.
  siteTitleAlt: "Test", // Alternative site title for SEO.
  siteLogo: "/icons/icon-512x512.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.starter.com", // Domain of your website without pathPrefix.
  canonicalUrl: "https://...com",
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: "Starter", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml",
  siteFBAppID: "", // FB Application ID for using app insights
  googleTagManagerID: "", // GTM tracking ID.
  userName: "Timo Müller",
  userTwitter: "",
  userLocation: "Berlin, Germany",
  userDescription: "",
  copyright: "©2020 Berlin", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#00d1b2", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff" // Used for setting manifest background color.
}
