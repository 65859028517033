import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Location } from "@reach/router"
import { Layout, Menu, Drawer, Icon } from "antd"
import { Container } from "../Container"
import CustomLink from "../../Helpers/CustomLink"
import "./styles.less"
import { isMobile } from "../../../utils"

const { Header } = Layout
const { SubMenu } = Menu

class LeftMenu extends React.Component {
  render() {
    return (
      <div className="logo">
        <CustomLink location="/">
          <img src={this.props.logo} alt="leaders-for-climate-action" />
        </CustomLink>
      </div>
    )
  }
}

class RightMenu extends React.Component {
  render() {
    return (
      <Menu
        mode={!isMobile() ? "horizontal" : "inline"}
        selectedKeys={[this.props.activePath]}
      >
        {this.props.items &&
          this.props.items.map(item => {
            if (item.linkType === "submenu") {
              return (
                <SubMenu
                  key={item.location}
                  title={
                    <span className="submenu-title">
                      {item.text}
                      <Icon type="down" />
                    </span>
                  }
                >
                  {item.sub_menu_items.map(subItem => (
                    <Menu.Item key={`sub-${subItem.location}`}>
                      <CustomLink
                        location={subItem.location}
                        linkType={subItem.linkType}
                      >
                        {subItem.text}
                      </CustomLink>
                    </Menu.Item>
                  ))}
                </SubMenu>
              )
            } else {
              return (
                <Menu.Item key={item.location}>
                  <CustomLink location={item.location} linkType={item.linkType}>
                    {item.text}
                  </CustomLink>
                </Menu.Item>
              )
            }
          })}
      </Menu>
    )
  }
}

class PageHeader extends React.Component {
  state = {
    visible: false
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }

  onClose = () => {
    this.setState({ visible: false })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SearchIndexQuery {
            allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "/settings/" } }
            ) {
              edges {
                node {
                  frontmatter {
                    menu_items {
                      text
                      linkType
                      location
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ allMarkdownRemark: { edges } }) => (
          <Location>
            {({ navigate, location }) => {
              const menuItems = edges[0].node.frontmatter.menu_items
              const hamburgerClass = `hamburger hamburger--spin ${this.state
                .visible && "is-active"}`
              return (
                <Header className="page-header">
                  <Container>
                    <nav className="menu-bar">
                      <div className="menu-con">
                        <div className="left-menu">
                          <LeftMenu logo={"/img/logo_white.png"} />
                        </div>
                        <div className="right-menu">
                          <RightMenu
                            activePath={location.pathname}
                            items={menuItems}
                          />
                        </div>
                        <button
                          className={hamburgerClass}
                          type="button"
                          onClick={this.showDrawer}
                        >
                          <span className="hamburger-box">
                            <span className="hamburger-inner" />
                          </span>
                        </button>
                        <Drawer
                          className="nav-drawer"
                          placement="left"
                          closable={false}
                          onClose={this.onClose}
                          visible={this.state.visible}
                        >
                          <h2>Navigation</h2>
                          <RightMenu items={menuItems} />
                        </Drawer>
                      </div>
                    </nav>
                  </Container>
                </Header>
              )
            }}
          </Location>
        )}
      />
    )
  }
}

export default PageHeader
