import React from "react"
import config from "../../../../config"
import { Layout, Row, Col, List, Button } from "antd"
import { StaticQuery, graphql } from "gatsby"
import CustomLink from "../../Helpers/CustomLink"
import "./styles.less"
const { Footer, Content } = Layout

const LinkList = ({ list }) => {
  return (
    <List
      dataSource={list}
      className="link-list"
      itemLayout="vertical"
      renderItem={item => (
        <List.Item>
          <CustomLink location={item.location} linkType={item.linkType}>
            {item.text}
          </CustomLink>
        </List.Item>
      )}
    />
  )
}

class PageFooter extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "/settings/" } }
            ) {
              edges {
                node {
                  frontmatter {
                    impressum
                    twitter
                    linkedin
                    facebook
                    address
                    address_link
                    footer_items {
                      text
                      linkType
                      location
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ allMarkdownRemark: { edges } }) => {
          const frontmatter = edges[0].node.frontmatter
          return (
            <Footer>
              <Content>
                <Row style={{ textAlign: "center" }}>
                  <Col xs={24}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={frontmatter.address_link}
                    >
                      <Button
                        ghost
                        icon="environment"
                        style={{ margin: "0 0 30px", fontWeight: "100" }}
                      >
                        {frontmatter.address}
                      </Button>
                    </a>
                  </Col>
                  <Col xs={24}>
                    <div className="links">
                      <LinkList list={frontmatter.footer_items} />
                    </div>
                  </Col>
                </Row>
                {/* <Row className="copyright" justify="center" type="flex">
                  <Col>{config.copyright}</Col>
                </Row> */}
              </Content>
            </Footer>
          )
        }}
      />
    )
  }
}

export default PageFooter
